import { Container, Row, Col, Button, Image } from 'react-bootstrap'


export default function Post({post}) {
    var date = new Date(post.date);

    var formattedDate = (new Intl.DateTimeFormat('pl-PL', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(date));


    return (<div>
        <div className='border border-light p-4' style={{ marginTop: '1rem', marginBottom: '3rem' }}>
            <Row>
                <Col md={8}>
                    <h5 className='text-white display-6'>{post.title}</h5>
                </Col>
                <Col md={4}>
                    <div className='text-white text-end'>{formattedDate}</div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className='text-white' dangerouslySetInnerHTML={{ __html: post.content }} />
                </Col>
            </Row>
        </div>
    </div>)
}