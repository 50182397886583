
import { gql } from "@apollo/client";

export const LOAD_WORKSHOPS = gql`
query {
  category(id: "dGVybToz") {
    id
    posts {
      nodes {
        id
        title
        content
      }
    }
  }
}
`;
