import React, { useState } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import Modal from 'react-bootstrap/Modal';
import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import {  LOAD_VOUCHER } from '../graphQL/Pages'


function Voucher() {
    const pageTitle = 'Voucher'

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [page, setPage] = useState({
        title: "",
        content: ""
      });
    
      const { error, loading, data } = useQuery(LOAD_VOUCHER);
    
      useEffect(() => {
        if (data) {
            setPage({
            title: data.pageBy.title,
            content: data.pageBy.content
          });
        }
      }, [data]);

    return (
        <div style={{ textAlign: 'justify' }}>
            <Meta title={pageTitle} />
            <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
                <Row>
                    <Col md={5}>
                        <Image className='img-shadow mb-3' src="img/tiles/voucher2.jpg" style={{ width: '100%' }} />
                        <Image className='img-shadow mb-3' src="img/tiles/voucher.jpg" style={{ width: '100%' }} />
                    </Col>
                    <Col md={7}>
                        <h3 className='text-white display-6'>
                            {pageTitle}
                        </h3>
                        <div className='mt-3' dangerouslySetInnerHTML={{ __html: page.content }} />
                  </Col>
                </Row>
                <Row>
                    <Button className='btn2 draw-border' onClick={handleShow}>
                        Zamów Voucher
                    </Button>
                    <Col md={12}>
                        <Modal show={show} onHide={handleClose}>
                            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScfxq3I2WGzg2VWWrqSCDYKD8tgwnkbobmdgQ_vDl35cuonGQ/viewform?embedded=true" width="100%" height="1300px%" frameborder="0" marginheight="0" marginwidth="0">Ładuję…</iframe>
                            <Button variant="secondary" onClick={handleClose}>
                                Zamknij
                            </Button>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Voucher