import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import Post from './PostComponent';


export default function PostList({posts}) {
    const postList = posts?.map(post =>
        <Row>
            <Col md={12}>
                <Post post={post.node}> </Post>
            </Col>
        </Row>
    );

    return (<div>
        <div style={{ marginTop: '3rem', marginBottom: '3rem' }}>
            {postList}
        </div>
    </div>)
}