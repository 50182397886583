import { gql } from "@apollo/client";

export const LOAD_OPINIONS = gql`
query {
  posts(where: {categoryId: 8}) {
    edges {
      node {
        id
        content
        date
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
}
`;
