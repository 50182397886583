// router
import { BrowserRouter as Router } from 'react-router-dom'

// components
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import ScrollToTop from '../hooks/ScrollToTop'

const Layout = ({ children }) => {
  return (
    <Router>
      <ScrollToTop />
      <Menu />
      <main className='section-shaped'>{children}</main>
      <Footer />
    </Router>
  )
}

export default Layout
