import { Container, Row, Col, Card } from 'react-bootstrap'
import { BiUser, BiMailSend, BiPhone, BiMap } from 'react-icons/bi'
import Meta from '../components/Meta'


const Contact = () => {
    // page content
    const pageTitle = 'Kontakt'

    return (
        <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
            <Meta title={pageTitle} />
            <Row>
                <Col md={7}>
                    <iframe title='FormlarzKotaktowy' style={{ width: '100%' }}
                        src="https://docs.google.com/forms/d/e/1FAIpQLScU3C2UZg_VmR_2cKOfSyLr2RORgo7kRywZ01Q1eXVwT8KW8w/viewform?embedded=true"
                        height="780rem" frameBorder="0" marginHeight="0" marginWidth="0">Ładuję…</iframe>
                </Col>
                <Col md={5}>
                    <Card className='img-shadow mt-4'>
                        <Card.Body>

                            <Card.Title className=' text-primary'>Skontaktuj się ze mną</Card.Title>
                            <Card.Text className='mt-3'>
                                <Row>
                                    <Col xs={2}>
                                        <div className='icon-shape-primary icon-shape rounded-circle'>
                                            <BiUser className='' size={16} />
                                        </div></Col>
                                    <Col>
                                        <div className='mt-2'>Ewelina Stachura</div>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col xs={2}>
                                        <div className='icon-shape-primary icon-shape rounded-circle'>
                                            <BiMailSend className='' size={16} />
                                        </div></Col>
                                    <Col><div className='mt-2'>balansfit@ewelinastachura.pl</div></Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col xs={2}>
                                        <div className='icon-shape-primary icon-shape rounded-circle'>
                                            <BiPhone className='' size={16} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='mt-2'>505 911 990</div>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col xs={2}>
                                        <div className='icon-shape-primary icon-shape rounded-circle'>
                                            <BiMap className='' size={16} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='mt-2'>ul. Radockiego 282, Katowice  (trening i konsultacje na sali)</div>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Contact
