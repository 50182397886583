import { Route, Switch } from 'react-router-dom'

// Layout
import Layout from './layout/Layout'

// pages
import Home from './pages/Home'
import About from './pages/About'
import NotFound from './pages/NotFound'
import Schedule from './pages/Schedule'
import Contact from './pages/Contact'
import PersonalTraining from './pages/PersonalTraining'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import Offer from './pages/Offer'
import PirceList from './pages/PirceList'
import Yoga from './pages/Yoga'
import Bowen from './pages/Bowen'
import Workshops from './pages/Workshops'
import Seniors from './pages/Seniors'
import Online from './pages/Online'
import Kobido from './pages/Kobido'
import Voucher from './pages/Voucher'
import Blog from './pages/Blog'
import GroupTraining from './pages/GroupTraining'
import Consultations from './pages/Consultations'
import ExerciseSets from './pages/ExerciseSets'
import Newsletter from './pages/Newsletter'

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      console.log(`Graphql error ${message}`);
    });
  }
});

const link = from([
  errorLink,
  new HttpLink({ uri: "https://ewelinastachura.pl/api/graphql" }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});


const App = () => {
  return (
    <ApolloProvider client={client}>
      <Layout>
        <Switch>
          <Route path='/' component={Home} exact />
          <Route path='/o-mnie' component={About} />
          <Route path='/oferta' component={Offer} />
          <Route path='/joga' component={Yoga} />
          <Route path='/terapia-bowena' component={Bowen} />
          <Route path='/zajecia-indywidualne' component={PersonalTraining} />
          <Route path='/warsztaty' component={Workshops} />
          <Route path='/seniorzy' component={Seniors} />
          <Route path='/cennik' component={PirceList} />
          <Route path='/grafik' component={Schedule} />
          <Route path='/kontakt' component={Contact} />
          <Route path='/online' component={Online} />
          <Route path='/kobido' component={Kobido} />
          <Route path='/voucher' component={Voucher} />
          <Route path='/blog' component={Blog} />
          <Route path='/zajecia-grupowe' component={GroupTraining} />
          <Route path='/konsultacje' component={Consultations} />
          <Route path='/gotowe-zestawy-cwiczen' component={ExerciseSets} />
          <Route path='/newsletter' component={Newsletter} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </ApolloProvider>
  )
}

export default App
