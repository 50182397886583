import { Container, Row, Col, ListGroup, Image } from 'react-bootstrap'
import Meta from '../components/Meta'
import React from 'react'
import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { LOAD_SENIORS } from '../graphQL/Pages'

function Seniors() {
    const pageTitle = 'Seniorzy'

    const [page, setPage] = useState({
        title: "",
        content: ""
      });
    
      const { error, loading, data } = useQuery(LOAD_SENIORS);
    
      useEffect(() => {
        if (data) {
            setPage({
            title: data.pageBy.title,
            content: data.pageBy.content
          });
        }
      }, [data]);
	  
    
    return (
        <div style={{ textAlign: 'justify'}}>
            <Meta title={pageTitle} />
            <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
                <Row>
                    <Col md={5}>
                        <Image className='img-shadow mb-5' src="img/articles/seniors.jpg" style={{ width: '100%' }} />
                    </Col>
                    <Col md={7}>
                        <h3 className='text-white display-6'>
                            {pageTitle}
                        </h3>
                        <div className='mt-3' dangerouslySetInnerHTML={{ __html: page.content }} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Seniors
