import { Container, Modal, Button, Row, Col } from 'react-bootstrap'
import Meta from '../components/Meta'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client'
import { LOAD_SCHEDULE } from '../graphQL/ScheduleQuery'
import { BsFillClockFill, BsPinMapFill, BsFillFileEarmarkTextFill } from 'react-icons/bs'


const Schedule = () => {
    // page content
    const pageTitle = 'Grafik'


    const [show, setShow] = useState(false);
    const [event, setEvent] = useState({
        "id": '',
        "title": '',
        "startTime": '',
        "endTime": '',
        "description": '',
        "address": ''
    });
    const [eventList, setEventList] = useState(
        {
            events: []
        }
    );

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { error, loading, data } = useQuery(LOAD_SCHEDULE);

    useEffect(() => {
        if (data) {
            //console.log(GetEventListFromData(data))
            setEventList({
                events: GetEventListFromData(data)
            })
        }
    }, [data]) //gdy zmieni się data odpala się useEffect

    function GetEventListFromData(data) {
        if (data.category.posts.nodes) {
            var posts = data.category.posts.nodes;

            return posts.map(x => ({
                id: x.id,
                title: x.title,
                startTime: x.calendar.starttime,
                endTime: x.calendar.endtime,
                description: x.calendar.description,
                daysOfWeek: [x.calendar.daysofweek],
                address: x.calendar.address
            }));
        }
    }

    function eventClick(info) {
        setEvent(getEventData(info.event.id));
        handleShow();
    }

    function getEventData(selectedId) {
        var event = eventList.events.find(x => x.id === selectedId);

        return event;
    }

    return (
        <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }} >
            <h1 className='text-white'>{pageTitle}</h1>
            <div><Meta title={pageTitle} />
                <FullCalendar
                    locale='pl'
                    themeSystem=''
                    plugins={[dayGridPlugin, bootstrapPlugin, timeGridPlugin]}
                    initialView="timeGridWeek"
                    weekends={false}
                    allDaySlot={false}
                    slotDuration={'01:00'}
                    slotLabelInterval={'02:00'}
                    slotMinTime={'08:00'}
                    slotMaxTime={'22:00'}
                    slotLabelFormat={[{ hour: 'numeric', minute: 'numeric' }]}
                    height={'55rem'}
                    eventClick={(info) => {
                        eventClick(info)
                    }
                    }
                    events={eventList}
                />
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{event.title} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={event.description ? '' : 'd-none'}>
                        <Col sm={1}>
                            <BsFillFileEarmarkTextFill size={24} color='#265f72'></BsFillFileEarmarkTextFill>
                        </Col>
                        <Col sm={11} className='mt-1'>
                            <p>
                                {event.description}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={1} >
                            <BsFillClockFill size={24} color='#265f72'></BsFillClockFill>
                        </Col>
                        <Col sm={11} className='mt-1'>
                            <p>
                                {event.startTime} - {event.endTime}
                            </p>
                        </Col>
                    </Row>
                    <Row className={event.description ? '' : 'd-none'}>
                        <Col sm={1}>
                            <BsPinMapFill size={24} color='#265f72'></BsPinMapFill>
                        </Col>
                        <Col sm={11} className='mt-1'>
                            <p>
                                {event?.address?.split(';').map(x => {
                                    return <p>{x}</p>
                                })}
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container >
    )
}

export default Schedule
