
import { gql } from "@apollo/client";

export const LOAD_ABOUT = gql`
query {
    pageBy(pageId: 223) {
      id
      title
      content
    }
  }
`;

export const LOAD_YOGA = gql`
query {
    pageBy(pageId: 239) {
      id
      title
      content
    }
  }
`;

export const LOAD_BOWEN = gql`
query {
    pageBy(pageId: 247) {
      id
      title
      content
    }
  }
`;

export const LOAD_PERSONAL = gql`
query {
    pageBy(pageId: 249) {
      id
      title
      content
    }
  }
`;

export const LOAD_KOBIDO = gql`
query {
    pageBy(pageId: 251) {
      id
      title
      content
    }
  }
`;

export const LOAD_SENIORS = gql`
query {
    pageBy(pageId: 253) {
      id
      title
      content
    }
  }
`;

export const LOAD_ONLINE = gql`
query {
    pageBy(pageId: 255) {
      id
      title
      content
    }
  }
`;

export const LOAD_VOUCHER = gql`
query {
    pageBy(pageId: 257) {
      id
      title
      content
    }
  }
`;

export const LOAD_BLOG = gql`
query {
  posts(where: {categoryId: 7}) {
    edges {
      node {
        id
        content
        date
        title
      }
    }
  }
}
`;

export const LOAD_CONSULTATIONS = gql`
query {
    pageBy(pageId: 345) {
      id
      title
      content
    }
  }
`;

export const LOAD_GROUP_TRAINNING = gql`
query {
    pageBy(pageId: 347) {
      id
      title
      content
    }
  }
`;

export const LOAD_EXERCISE_SETS = gql`
query {
    pageBy(pageId: 349) {
      id
      title
      content
    }
  }
`;

export const LOAD_NEWSLETTER = gql`
query {
    pageBy(pageId: 403) {
      id
      title
      content
    }
  }
`;