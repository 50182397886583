import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import Meta from '../components/Meta'
import { LOAD_NEWSLETTER } from '../graphQL/Pages'

const Newsletter = () => {
  // page content
  const pageTitle = 'Newsletter'

  const [page, setPage] = useState({
    title: "",
    content: ""
  });

  const { error, loading, data } = useQuery(LOAD_NEWSLETTER);

  useEffect(() => {
    if (data) {
        console.log('test ' + data.pageBy);
        setPage({
        title: data.pageBy.title,
        content: data.pageBy.content
      });
    }
  }, [data]);




  return (
    <>
    <div style={{ textAlign: 'justify' }}>
        <Meta title={pageTitle} />
        <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
            <Row>
                <Col md={12}>
                    <div className='mt-3' dangerouslySetInnerHTML={{ __html: page.content }} />
                </Col>
            </Row>
        </Container>
    </div>
    </>
)
}

export default Newsletter