import { gql } from "@apollo/client";

export const LOAD_SCHEDULE = gql`
query {
  category(id: "dGVybToy") {
    id
    posts(first: 40) {
      nodes {
        id
        title
        calendar {
          daysofweek
          endtime
          fieldGroupName
          starttime,
          description,
          address
        }
      }
    }
  }
}
`;
