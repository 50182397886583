import {
  Nav,
  Navbar,
  Container,
  NavDropdown,
  NavbarBrand,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { useState } from "react";
import {
  FaEnvelope,
  FaFacebookSquare,
  FaInstagram,
  FaPaperPlane,
  FaVideo,
  FaYoutube,
} from "react-icons/fa";

const Menu = () => {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      style={{ background: "#3D7A8E" }}
    >
      <Container>
        <NavbarBrand>
          <LinkContainer to="/" style={{ cursor: "pointer" }}>
            <Image src="img/brand/logo2.png" style={{ maxHeight: "60px" }} />
          </LinkContainer>
        </NavbarBrand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <LinkContainer to="/o-mnie">
              <Nav.Link className="btn2 without-border">O mnie</Nav.Link>
            </LinkContainer>
            <NavDropdown
              title="Oferta"
              id="collasible-nav-dropdown"
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
            >
              <LinkContainer to="/konsultacje">
                <NavDropdown.Item className="btn2 without-border">
                  Konsultacja 1:1
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/zajecia-indywidualne">
                <NavDropdown.Item className="btn2 without-border">
                  Trening indywidualny
                </NavDropdown.Item>
              </LinkContainer>
              <div>
                <a
                  className="btn2 without-border dropdown-item"
                  href="https://preview.mailerlite.io/preview/904647/sites/123848777195849025/LBKrw4"
                  target="_blank"
                >
                  Kurs online "Zrób w końcu szpagat"
                </a>
              </div>
              <LinkContainer to="/warsztaty">
                <NavDropdown.Item className="btn2 without-border">
                  Warsztaty i wyjazdy
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/grafik">
                <NavDropdown.Item className="btn2 without-border">
                  Zajęcia grupowe
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/voucher">
                <NavDropdown.Item className="btn2 without-border">
                  Voucher
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to="/grafik">
              <Nav.Link className="btn2 without-border">Grafik</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/cennik">
              <Nav.Link className="btn2 without-border">Cennik</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/kontakt">
              <Nav.Link className="btn2 without-border">Kontakt</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/blog">
              <Nav.Link className="btn2 without-border">Blog</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
        <div>
          <a
            href="https://www.facebook.com/BalansFitEwelinaStachura"
            target="_blank"
          >
            <FaFacebookSquare
              className=""
              size={20}
              style={{ color: "#ffffff", marginRight: "0.5rem" }}
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.instagram.com/balansfit_ewelinastachura"
            target="_blank"
          >
            <FaInstagram
              className=""
              size={20}
              style={{ color: "#ffffff", marginRight: "0.5rem" }}
            />
          </a>
        </div>
        <div>
          <a href="https://www.youtube.com/@BalansFit" target="_blank">
            <FaYoutube className="" size={20} style={{ color: "#ffffff" }} />
          </a>
        </div>
        <div>
          <LinkContainer to="/newsletter">
            <a>
              <FaPaperPlane
                className="ms-2"
                size={16}
                style={{ color: "#ffffff" }}
              />
            </a>
          </LinkContainer>
        </div>
      </Container>
    </Navbar>
  );
};

export default Menu;
