import Meta from '../components/Meta'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { LOAD_PERSONAL } from '../graphQL/Pages'

const PersonalTraining = () => {
    // page content
    const pageTitle = 'Trening indywidualny'

    const [personalPage, setPersonalPage] = useState({
        title: "",
        content: ""
      });
    
      const { error, loading, data } = useQuery(LOAD_PERSONAL);
    
      useEffect(() => {
        if (data) {
            setPersonalPage({
            title: data.pageBy.title,
            content: data.pageBy.content
          });
        }
      }, [data]);

    return (
        <div style={{ textAlign: 'justify'}}>
            <Meta title={pageTitle} />
            <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
                <Row>
                    <Col md={5}>
                        <Image className='img-shadow mb-5' src="img/articles/trening-indywidualny.jpg" style={{ width: '100%' }} />
                    </Col>
                    <Col md={7}>
                        <h3 className='text-white display-6'>
                            {pageTitle}
                        </h3>
                        <div className='mt-3' dangerouslySetInnerHTML={{ __html: personalPage.content }} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PersonalTraining
