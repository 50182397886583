import { Container, Row, Col, ListGroup, Image, Button } from 'react-bootstrap'
import Meta from '../components/Meta'
import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { LOAD_KOBIDO } from '../graphQL/Pages'

function Kobido() {
    const pageTitle = 'Masaże'

	const [page, setPage] = useState({
        title: "",
        content: ""
      });
    
      const { error, loading, data } = useQuery(LOAD_KOBIDO);
    
      useEffect(() => {
        if (data) {
            setPage({
            title: data.pageBy.title,
            content: data.pageBy.content
          });
        }
      }, [data]);

    return (
        <div style={{ textAlign: 'justify' }}>
            <Meta title={pageTitle} />
            <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
                <Row>
                    <Col md={5}>
                        <Image className='img-shadow mb-5' src="img/articles/kobido.jpg" style={{ width: '100%' }} />
                    </Col>
                    <Col md={7}>
                        <h3 className='text-white display-6'>
                            {pageTitle}
                        </h3>
                        <div className='mt-3' dangerouslySetInnerHTML={{ __html: page.content }} />
                        <div className='btn-wrapper mt-4'>
                            <LinkContainer style={{ marginRight: '0.5rem' }} to='/kontakt'><Button className='btn2 draw-border '>Umów wizytę</Button></LinkContainer>
                            <LinkContainer to='/cennik'><Button className='btn2 draw-border'>Cennik</Button></LinkContainer>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Kobido