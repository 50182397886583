import { Container, Row, Col, ListGroup, Image } from 'react-bootstrap'
import Meta from '../components/Meta'
import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { useState, useEffect } from 'react';
import { LOAD_WORKSHOPS } from '../graphQL/WorkshopsQuery';

function Workshops() {
    const pageTitle = 'Warsztaty'

    const [article, setarticle] = useState(
        {
            workshop: []
        }
    );

    const { error, loading, data } = useQuery(LOAD_WORKSHOPS);

    useEffect(() => {
        if (data) {
            //console.log(GetArticlesFromData(data))
            setarticle({
                workshop: GetArticlesFromData(data)
            })
        }
    }, [data]) //gdy zmieni się data odpala się useEffect

    function GetArticlesFromData(data) {
        if (data.category.posts.nodes) {
            var article = data.category.posts.nodes;

            //console.log('art', article[0])
            return article.map(x => ({
                id: x.id,
                title: x.title,
                content: x.content
            }));
        }
    }

    return (
        <div style={{ textAlign: 'justify' }}>
            <Meta title={pageTitle} />
            <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
                <Row>
                    <Col md={5}>
                        <Image className='img-shadow md-5' src="img/articles/zajecia-grupowe.jpg" style={{ width: '100%' }} />

                        <p lead text-white mt-4></p>
                    </Col>
                    <Col md={7}>
                        <h3 className='text-white display-6'>
                            {pageTitle}
                        </h3>
                        <p className='text-white mt-4'>
                            <div dangerouslySetInnerHTML={{ __html: article.workshop[0]?.content }}/>
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Workshops
