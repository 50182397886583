import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { LOAD_YOGA } from '../graphQL/Pages'


function Yoga() {
    const pageTitle = 'Joga'

    const [yogaPage, setYogaPage] = useState({
        title: "",
        content: ""
      });
    
      const { error, loading, data } = useQuery(LOAD_YOGA);
    
      useEffect(() => {
        if (data) {
            setYogaPage({
            title: data.pageBy.title,
            content: data.pageBy.content
          });
        }
      }, [data]) //gdy zmieni się data odpala się useEffect

    return (
        <div style={{ textAlign: 'justify'}}>
            <Meta title={pageTitle} />
            <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
                <Row>
                    <Col md={5}>
                        <Image className='img-shadow mb-5' src="img/articles/joga.jpg" style={{ width: '100%' }} />
                    </Col>
                    <Col md={7}>
                        <h3 className='text-white display-6'>
                            {pageTitle}
                        </h3>
                        <div className='mt-3' dangerouslySetInnerHTML={{ __html: yogaPage.content }} />

                        <div className='btn-wrapper mt-4'>
                            <LinkContainer style={{ marginRight: '0.5rem' }} to='/zajecia-indywidualne'><Button className='btn2 draw-border '>Zajęcia indywidualne</Button></LinkContainer>
                            <LinkContainer to='/grafik'><Button className='btn2 draw-border'>Zajęcia grupowe</Button></LinkContainer>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Yoga
