import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Container, Row, Button, Col, Image } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import React from 'react'
import OpinionsComponent from '../components/OpinionsComponent';

const Home = () => {

  return (
    <div>
      <div id='home-photo'>
        <LazyLoadImage src="img/bg/bg1.jpg" style={{ maxWidth: '100%' }}></LazyLoadImage>
      </div>
      <Container className='full-width' style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
        <Row>
          <Col xl={4}>
            <div className='grid'>
              <figure class="effect-sadie">
                <img src="img/tiles/konsultacje.jpg" alt="Konsultacje" />
                <figcaption>
                  <h2><span>Konsultacje 1:1 </span></h2>
                  <p>- Program ćwiczeń, mający na celu naukę bezpiecznych i efektywnych technik treningowych, co pozwala maksymalizować korzyści przy minimalnym ryzyku bólu i urazów
                   <br></br> 
                   - Jak ćwiczyć bezpiecznie i efektywnie
                   <br></br><LinkContainer to='/konsultacje'><Button className='btn2 draw-border mt-2'>Dowiedz się więcej</Button></LinkContainer>
                   </p>
                </figcaption>
              </figure>
            </div>
          </Col>
          <Col xl={4}>
            <div className='grid'>
              <figure class="effect-sadie">
                <img src="img/tiles/3.jpg" alt="Zajęcia indywidualne" />
                <figcaption>
                  <h2><span>Trening indywidualny</span></h2>
                  <p>Jeśli potrzebujesz motywacji oraz wsparcia do bezpiecznej i efektywnej pracy nad formą zapraszam na 60 minutowe sesje treningowe na sali lub online
                  <br></br> 
                    <LinkContainer to='/zajecia-indywidualne'><Button className='btn2 draw-border mt-2'>Dowiedz się więcej</Button></LinkContainer></p>
                </figcaption>
              </figure>
            </div>
          </Col>
          <Col xl={4}>
            <div className='grid'>
              <figure class="effect-sadie">
                <img src="img/tiles/kurs-online.jpg" alt="zestawy-cwiczen" />
                <figcaption>
                  <h2>Kurs online <br></br> <span>"Zrób w końcu szpagat"</span></h2>
                  <p>- Twój 3-tygodniowy kurs krok po kroku do elastyczności
                  <br></br>
                    <a href='https://preview.mailerlite.io/preview/904647/sites/123848777195849025/LBKrw4' target='_blank'><Button className='btn2 draw-border mt-2'>Dowiedz się więcej</Button></a></p>
                </figcaption>
              </figure>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={4}>
            <div className='grid'>
              <figure class="effect-sadie">
                <img src="img/tiles/warsztaty.jpg" alt="Warsztaty" />
                <figcaption>
                  <h2><span>Warsztaty</span></h2>
                  <p>Zapraszam Cię na wspólne wyjazdy z jogą oraz warsztaty stacjonarne<LinkContainer to='/warsztaty'><Button className='btn2 draw-border mt-2'>Dowiedz się więcej</Button></LinkContainer></p>
                </figcaption>
              </figure>
            </div>
          </Col>
          <Col xl={4}>
            <div className='grid'>
              <figure class="effect-sadie">
                <img src="img/tiles/voucher.jpg" alt="voucher" />
                <figcaption>
                  <h2><span>Voucher</span></h2>
                  <p>Doskonały prezent dla bliskiej Ci osoby, spraw jej przyjemność!<br></br>
                      <br></br>
                      - Konsultacje 1:1 
                      <br></br>
                      - Trening indywidulany
                     <br></br><LinkContainer to='/voucher'><Button className='btn2 draw-border mt-2'>Dowiedz się więcej</Button></LinkContainer></p>
                </figcaption>
              </figure>
            </div>
          </Col>
          <Col xl={4}>
            <div className='grid'>
              <figure class="effect-sadie">
                <img src="img/tiles/zajecia-grupowe.jpg" alt="zajecia-grupowe" />
                <figcaption>
                  <h2><span>Zajęcia grupowe</span></h2>
                  <p>- Joga<br></br>
                  - Zajęcia dla seniorów
                  <br></br>
                  <LinkContainer to='/grafik'><Button className='btn2 draw-border mt-2'>Dowiedz się więcej</Button></LinkContainer></p>
                </figcaption>
              </figure>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "8rem" }}>
          <OpinionsComponent></OpinionsComponent>
        </Row>
      </Container>
    </div>
  )
}

export default Home
