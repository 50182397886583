import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Meta from "../components/Meta";
import { LOAD_ABOUT } from "../graphQL/Pages";
import OpinionsComponent from "../components/OpinionsComponent";

const About = () => {
  // page content
  const pageTitle = "O mnie";

  const [aboutPage, setAboutPage] = useState({
    title: "",
    content: "",
  });

  const { error, loading, data } = useQuery(LOAD_ABOUT);

  useEffect(() => {
    if (data) {
      setAboutPage({
        title: data.pageBy.title,
        content: data.pageBy.content,
      });
    }
  }, [data]); //gdy zmieni się data odpala się useEffect

  return (
    <div style={{ textAlign: "justify" }}>
      <Meta title={pageTitle} />
      <Container style={{ paddingTop: "5rem", paddingBottom: "3rem" }}>
        <Row>
          <Col md={5}>
            <Image
              className="img-shadow mb-5"
              src="img/articles/001.jpg"
              style={{ width: "100%" }}
            />
          </Col>
          <Col md={7}>
            <h3 className="text-white display-6">O mnie</h3>
            <br></br>
            <div dangerouslySetInnerHTML={{ __html: aboutPage.content }} />
            <div className="btn-wrapper mt-4">
              <LinkContainer
                style={{ marginRight: "0.5rem" }}
                to="/konsultacje"
              >
                <Button className="btn2 draw-border ">Konsultacje</Button>
              </LinkContainer>
              <LinkContainer
                style={{ marginRight: "0.5rem" }}
                to="/zajecia-indywidualne"
              >
                <Button className="btn2 draw-border ">
                  Trening indywidualny
                </Button>
              </LinkContainer>
              <LinkContainer
                style={{ marginRight: "0.5rem" }}
                to="/gotowe-zestawy-cwiczen"
              >
                <Button className="btn2 draw-border ">
                  Kurs online "Zrób w końcu szpagat"
                </Button>
              </LinkContainer>
              <LinkContainer to="/grafik">
                <Button className="btn2 draw-border">Zajęcia grupowe</Button>
              </LinkContainer>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "8rem" }}>
          <OpinionsComponent></OpinionsComponent>
        </Row>
      </Container>
    </div>
  );
};

export default About;
