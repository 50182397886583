import { Button, Container, Row, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <>
      <footer className='text-center text-white text-capitalize bf-footer' style={{ background: '#3D7A8E' }}>
        <Container>
          <Row>
            <hr></hr>
            <p>
              <LinkContainer to='/newsletter'><a className='text-white font-12 ms-1'>Newsletter</a></LinkContainer> || <a className='text-white font-12' href='docs/polityka_prywatnosci.pdf'  target='_blank'>Polityka prywatności</a> | <a className='text-white font-12' href='docs/regulamin_newslettera.pdf' target='_blank'>Regulamin newslettera</a> | <a className='text-white font-12' href='docs/regulamin_sklepu.pdf' target='_blank'>Regulamin sklepu</a>
             </p>
            <p> Studio BalansFit Ewelina Stachura &copy; {year}</p>
            <hr></hr>
          </Row>
        </Container>
        <style>{"\
        .font-12{\
          font-size: 12px;\
        }\
      "}</style>
      </footer>
    </>
  )
}

export default Footer
