import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import Meta from '../components/Meta'
import { LOAD_BLOG } from '../graphQL/Pages'
import PostList from '../components/PostListComponent'

const Blog = () => {
  // page content
  const pageTitle = 'Blog'

  const [postsData, setBlogPage] = useState({
    posts: {
      edges: [{
        node: {
          id: "",
          title: "test",
          content: "test1",
          date: new Date()
        }
      }]
    }
  });

  const { error, loading, data } = useQuery(LOAD_BLOG);

  useEffect(() => {
    if (data) {
      console.log(data.posts)
      setBlogPage({
        posts: data.posts
      });
    }
  }, [data]) //gdy zmieni się data odpala się useEffect



  return (
    <div style={{ textAlign: 'justify' }}>
      <Meta title={pageTitle} />
      <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
        <Row>
          <Col md={12}>
            <h3 className='text-white display-6'>
              Blog
            </h3>
          </Col>
        </Row>
        <PostList posts={postsData.posts.edges}></PostList>
      </Container>
    </div >
  )
}

export default Blog
