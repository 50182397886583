import Meta from '../components/Meta'
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { LOAD_GROUP_TRAINNING } from '../graphQL/Pages';

const GroupTraining = () => {
    // page content
    const pageTitle = 'Zajęcia grupowe'

    const [personalPage, setPersonalPage] = useState({
        title: "",
        content: ""
      });
    
      const { error, loading, data } = useQuery(LOAD_GROUP_TRAINNING);
    
      useEffect(() => {
        if (data) {
            setPersonalPage({
            title: data.pageBy.title,
            content: data.pageBy.content
          });
        }
      }, [data]);

    return (
        <div style={{ textAlign: 'justify'}}>
        <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
            <Meta title={pageTitle} />
            <Row>
                <Col sm={4}>
                    <Image className='img-shadow' src="img/articles/zajecia-grupowe.jpg" style={{ width: '100%' }} />
                </Col>
                <Col sm={7}>
                <h3 className='text-white display-6'>
                            {pageTitle}
                        </h3>
                        <div className='mt-3' dangerouslySetInnerHTML={{ __html: personalPage.content }} />
                </Col>
            </Row>
        </Container></div>
    )
}

export default GroupTraining
