import { Container, Row, Col, ListGroup, Image, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { LOAD_BOWEN } from '../graphQL/Pages'

function Bowen() {
    const pageTitle = 'Terapia Bowena'

    const [bowenPage, setBowenPage] = useState({
        title: "",
        content: ""
      });
    
      const { error, loading, data } = useQuery(LOAD_BOWEN);
    
      useEffect(() => {
        if (data) {
            setBowenPage({
            title: data.pageBy.title,
            content: data.pageBy.content
          });
        }
      }, [data]);

    return (
        <div style={{ textAlign: 'justify'}}>
            <Meta title={pageTitle} />
            <Container style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
                <Row>
                    <Col md={5}>
                        <Image className='img-shadow mb-5' src="img/articles/bowen.jpg" style={{ width: '100%' }} />
                    </Col>
                    <Col md={7}>
                        <h3 className='text-white display-6'>
                            {pageTitle}
                        </h3>

                        <div className='mt-3' dangerouslySetInnerHTML={{ __html: bowenPage.content }} />
                        
                        <LinkContainer style={{ marginRight: '0.5rem' }} to='/grafik'><Button className='btn2 draw-border'>Grafik</Button></LinkContainer>
                            <LinkContainer style={{ marginRight: '0.5rem' }} to='/kontakt'><Button className='btn2 draw-border '>Umów wizytę</Button></LinkContainer>
                            <LinkContainer to='/cennik'><Button className='btn2 draw-border'>Cennik</Button></LinkContainer>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Bowen
