import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import { LOAD_OPINIONS } from "../graphQL/OpinionsQuery";

const OpinionsComponent = () => {
  const [opinionsList, setOpinionsList] = useState([]);
  const [carouselItems, setCarouselItems] = useState([]);

  const { error, loading, data } = useQuery(LOAD_OPINIONS);

  // Update opinionsList state when data is fetched
  useEffect(() => {
    if (data) {
      const opinions = GetOpinionsFromData(data);
      setOpinionsList(opinions);
    }
  }, [data]);

  // Update carouselItems state when opinionsList changes
  useEffect(() => {
    RefreshCarouselItems();
  }, [opinionsList]);

  // Transform data into desired format
  function GetOpinionsFromData(data) {
    if (data.posts.edges) {
      var posts = data.posts.edges;
      return posts.map((x) => ({
        id: x.node.id,
        name: x.node.title,
        text: x.node.content,
        image: x.node.featuredImage.node.sourceUrl,
      }));
    }
    return [];
  }

  // Create carousel items with 3 opinions each
  function RefreshCarouselItems() {
    const items = [];
    for (let i = 0; i < opinionsList.length; i += 3) {
      const chunk = opinionsList.slice(i, i + 3);
      items.push(
        <Carousel.Item key={i}>
          <Row>
            {chunk.map((op) => (
              <Col key={op.id} md={4}>
                <div className="opinion-card">
                  <img src={op.image} alt={op.name} className="opinion-image" />
                  <h5>{op.name}</h5>
                  <div dangerouslySetInnerHTML={{ __html: op.text }}></div>
                </div>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
      );
    }
    setCarouselItems(items);
  }

  // Render the component
  return (
    <Container>
      {opinionsList.length > 0 && (
        <h3 className="text-white text-center">Poznaj opinie moich klientek</h3>
      )}
      <Carousel controls={false} interval={4000}>
        {carouselItems}
      </Carousel>
    </Container>
  );
};

export default OpinionsComponent;
